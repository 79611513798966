<template>
  <div style="display: inline-flex;">
    <div style="position: relative;">
      <div style="position: absolute; left: 10px; top: 40%; border: 1px solid white; border-radius: 100%; width: 20px; cursor: pointer;" @click="advanceLeft">
        <span style="color: white">&lt;</span>
      </div>
    </div>

    <img
      v-for="(item, index) in items"
      :key="item.id"
      v-show="index == currentItem"
      style="max-width: 100%;"
      :src="imageURLfor( item.image )"
    />

    <div style="position: relative;">
      <div style="position: absolute; right: 10px; top: 40%; border: 1px solid white; border-radius: 100%; width: 20px; cursor: pointer;" @click="advanceRight">
          <span style="color: white">&gt;</span>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "@/sanity.js";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity)

export default {
        //<img v-for="img in gridItem.items" :key="img._key" :src="imageURLfor( img.image ).width(250)" />
  props: [
    'items'
  ],
  data() {
    return {
      currentItem: 0,
    }
  },
  computed: {
    totalItems() {
      return this.items.length - 1
    }
  },
  methods: {
    imageURLfor(source) {
      return imageBuilder.image(source)
    },
    advanceRight() {
      if (this.currentItem == this.totalItems) {
        this.currentItem = 0
      } else {
        this.currentItem++
      }
    },
    advanceLeft() {
      if (this.currentItem == 0) {
        this.currentItem = this.totalItems
      } else {
        this.currentItem--
      }
    },
  },
}
</script>
