<template>
  <div>
    <ul>
      <li v-for="c in clients" :key="c._key">
        {{ c.name }}
      </li>
    </ul>
  </div>
</template>

<script>

import sanity from "@/sanity.js";

export default {
  data() {
    return {
      clients: [],
    }
  },
  created() {
    const query = `*[_type == "clientList" && visible == true] {
      id,
      name,
      'clients': shownClients[]
    }[0...50]`;
    sanity.fetch(query).then(r => {
      console.log(r)
      this.clients = r.flatMap((c) => c.clients)
    })
  },
}

</script>
