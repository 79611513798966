import Vue from 'vue'
import App from './App.vue'

import "@/assets/global.css"

import VueRouter from 'vue-router'
Vue.use(VueRouter)

//import VueCompositionAPI from '@vue/composition-api'
//Vue.use(VueCompositionAPI)

Vue.config.productionTip = false

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);

import About from '@/views/About.vue'
import Home from '@/views/Home.vue'

const routes = [
  { path: '/', name: 'home', component: Home },
  { path: '/about', name: 'about', component: About }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
