<template>
  <div id="app">
    <nav>
      <MainIcon
        v-on:click.native="toggleTray"
        :class="{ 'tray-open': trayVisible }"
      />

      <transition name="tray">
        <div v-if="trayVisible" id="nav-tray">
          <div id="nav-inner">
            <div @click="toggleTray" class="close-icon">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                class="svg-inline--fa fa-times fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </div>

            <ul>
              <li>
                <router-link :to="{ name: 'about' }" @click.native="trayVisible = false">About</router-link>
              </li>
              <li>
                <router-link :to="{ name: 'home' }" @click.native="trayVisible = false">Home</router-link>
              </li>
            </ul>
          </div>
        </div>
      </transition>
    </nav>

    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import MainIcon from "@/components/MainIcon.vue";

export default {
  name: "App",
  data() {
    return {
      trayVisible: false,
    };
  },
  components: {
    MainIcon,
  },
  methods: {
    toggleTray() {
      this.trayVisible = !this.trayVisible;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.tray-enter-active,
.tray-leave-active {
  transition: all 0.3s ease;
}

.tray-enter {
  /* starting state for enter */
  opacity: 0;
  transform: translate(0, -50px);
}

.tray-enter-to {
  /* ending state for enter */
}

.tray-leave {
  /* starting state fore leave */
}

.tray-leave-to {
  /* ending state for leave */
  opacity: 0;
  transform: translate(0, -50px);
}

#nav-tray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  color: white;
  z-index: 1000;
  display: block;
  text-align: center;
}

#nav-inner {
  display: flex;
  justify-content: center;
}

#nav-inner ul {
  position: absolute;
  top: 25%;
  list-style-type: none;
  padding: 0;
}

#nav-inner ul li {
  display: block;
  font-size: 2em;
}

#nav-inner a {
  color: white;
  text-decoration: none;
}

.close-icon {
  cursor: pointer;
  width: 2em;
}
</style>
