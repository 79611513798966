<template>
  <main>
    <transition name="images">
      <masonry
        v-if="imagesLoaded"
        :cols="{ default: 5, 320: 1, 768: 2 }"
        :gutter="{ 320: '3px', default: '5px' }"
      >
        <div v-for="gridItem in gridMembers" :key="gridItem.id" style="margin-bottom: 3px margin-top: 0;">
          <!-- TODO: use variables for gutters -->
          <!-- TODO: need components for each of these -->

          <div v-if="gridItem._type == 'gridCollection'" >
            <ImageGallery :items="gridItem.items" />
          </div>

          <div v-else>
            <FadeinImage :image="gridItem.image.image" />
          </div>

        </div>
      </masonry>
    </transition>
  </main>
</template>

<script>

import sanity from "@/sanity.js";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity)

import ImageGallery from '@/components/ImageGallery.vue'
import FadeinImage from '@/components/FadeinImage.vue'

export default {
  data() {
    return {
        gridSet: null,
        imagesLoaded: false,
    }
  },
  computed: {
    gridMembers() {
      if (this.gridSet) {
        return this.gridSet.members
      }
      return []
    }
  },
  methods: {
    imageURLfor(source) {
      return imageBuilder.image(source)
    },
  },
  created() {
    const query = `*[_type == "gridSet"] {
      id,
      members[]->
    }[0]`;
    sanity.fetch(query).then(r => {
      this.gridSet = r
      this.imagesLoaded = true
    })
  },
  components: {
    FadeinImage,
    ImageGallery
  }
}

</script>
