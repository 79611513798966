<template>
  <transition name="fade">
    <img @load="onLoaded" v-show="loaded" style="max-width: 100%;" :src="imageURLfor( image ).maxWidth(500).auto('format')" />
  </transition>
</template>

<script>
import sanity from "@/sanity.js";
import imageUrlBuilder from "@sanity/image-url";
const imageBuilder = imageUrlBuilder(sanity)


export default {
  props: [
    'image',
    'maxWidth',
  ],
  data() {
    return {
      loaded: false,
    }
  },
  methods: {
    imageURLfor(source) {
      return imageBuilder.image(source)
    },
    onLoaded() {
      this.loaded = true;
    }
  },
}
</script>

<style>
.fade-enter-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}
</style>
